import React, { useEffect, useState } from "react";
import { Spin, Tooltip } from "antd";
import {
  totalOrderPrice,
  totalRegularOrderPrice,
  warnNotify,
} from "../../util";
import SinglePolicy from "./SinglePolicy";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  FETCH_COUPON_DETAILS_BY_CODE,
  FETCH_DISCOUNT_ON_PURCHASE,
  FETCH_POLICY,
} from "../../graphql/modules";
import { useSelector } from "react-redux";
import { AfterPaylogo, InfoIco } from "../Shared";
import { Link } from "react-router-dom";

const OrderSummary = ({
  cartItem,
  tax = 0.0,
  taxLoading,
  userData,
  type,
  setCouponDetails,
}) => {
  // fetch cart from redux

  const userEmail = useSelector((state) => state?.auth?.user?.email);
  const guestEmail = useSelector((state) => state?.guest?.email);
  const { shippingMethod, shippingMethodPre } = useSelector(
    (state) => state?.order
  );
  const [totalPrice, setTotalPrice] = useState(
    totalOrderPrice(cartItem, null, null)
  );
  const [couponCode, setCouponCode] = useState("");
  const [purchaseDiscount, setPurchaseDiscount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [couponResponseMsg, setCouponResponseMsg] = useState(false);

  const onCouponChange = (e) => {
    setCouponCode(e.target.value);
  };

  //fetch policy
  const { data: policyData, loading: policyLoading } = useQuery(FETCH_POLICY);
  const allPolicy = policyData?.FetchPrivacyAndPolicy?.result || [];
  // purchase discount fetch
  const { data } = useQuery(FETCH_DISCOUNT_ON_PURCHASE);
  const allOverPriceDiscount = data?.FetchOrderDiscount?.result;

  let totalItem = 0;
  cartItem.forEach((element) => {
    totalItem += element?.quantity;
  });

  useEffect(() => {
    if (allOverPriceDiscount?.length) {
      setPurchaseDiscount(
        totalRegularOrderPrice(cartItem, allOverPriceDiscount)
      );
    }
  }, [allOverPriceDiscount]);

  const [GetCouponDetails, { loading }] = useMutation(
    FETCH_COUPON_DETAILS_BY_CODE
  );

  const fetchCouponCode = async () => {
    try {
      const {
        data: { FetchCouponByCode },
      } = await GetCouponDetails({
        variables: {
          couponCode,
          email: userEmail || guestEmail,
        },
      });
      if (FetchCouponByCode?.success) {
        setCouponDetails(FetchCouponByCode?.coupon);
        window.localStorage.setItem(
          "coupon-code",
          JSON.stringify(FetchCouponByCode?.coupon)
        );
        const getCoupon = totalOrderPrice(
          cartItem,
          FetchCouponByCode?.coupon?.discountInPercent,
          true
        );
        console.log("=============> ", getCoupon);
        setDiscount(parseFloat(getCoupon[1]));
        setTotalPrice(getCoupon[0]);
        if (!getCoupon?.[2]) {
          setCouponResponseMsg("Cannot apply to discounted items");
        } else {
          setCouponResponseMsg("");
        }
      } else {
        // warnNotify(FetchCouponByCode?.message);
        setCouponResponseMsg("Coupon cannot be applied.");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const removeCoupon = () => {
    setDiscount(0);
    setTotalPrice(totalOrderPrice(cartItem));
    setCouponResponseMsg("");
    setCouponDetails({});
    window.localStorage.removeItem("coupon-code");
  };

  const totalCostSaveToLocal = () => {
    const ab =
      totalPrice +
      shippingMethod?.shippingCherge +
      (shippingMethodPre?.shippingCherge || 0) +
      (totalPrice - (discount ? 0 : purchaseDiscount)) * tax -
      (discount ? 0 : purchaseDiscount);
    localStorage.setItem("total-price", ab);
  };
  useEffect(() => {
    totalCostSaveToLocal();
  }, [
    purchaseDiscount,
    discount,
    totalPrice,
    tax,
    shippingMethod,
    shippingMethodPre,
  ]);

  return (
    <div className="col-md-4">
      <div className="mobile_c_h mt-3 mb-3">
        <div className="d-flex align-items-center">
          <span className="d-flex">
            <img src="/images/profile.svg" alt="logo" width="10px" />
          </span>
          <h5
            className="ms-2 w-100"
            style={{ fontSize: "14px", fontWeight: "700" }}
          >
            {userData?.isAuthenticated ? (
              "Hello " + userData?.user?.firstname
            ) : (
              <span className="text-uppercase">Guest Checkout</span>
            )}
          </h5>
        </div>
      </div>
      <div className="checkout4">
        <div className="summery">
          <div className="title">
            <div className="d-flex align-items-center">
              <span className="d-flex" style={{ marginTop: "-3px" }}>
                <img src="/images/shopping_bag.svg" alt="logo" width="13" />
              </span>
              <h5
                className="ms-2 w-100 text-uppercase"
                style={{ fontSize: "14px", fontWeight: "700" }}
              >
                Order Summary
              </h5>
            </div>
          </div>
          <hr />

          <div className="summary_count" style={{ marginTop: "-5px" }}>
            <ul className="summary_list">
              <li>Items ({totalItem}):</li>
              <li>{parseFloat(totalPrice + discount || 0).toFixed(2)} USD</li>
            </ul>
            <ul className="summary_list">
              <li>Shipping:</li>
              <li>
                {(
                  shippingMethod?.shippingCherge +
                  shippingMethodPre?.shippingCherge
                ).toFixed(2)}{" "}
                USD
              </li>
            </ul>
            <ul className="summary_list">
              <li>Estimated Tax:</li>
              <Spin spinning={taxLoading}>
                <li>
                  {(
                    (totalPrice - (discount ? 0 : purchaseDiscount)) *
                    tax
                  ).toFixed(2)}{" "}
                  USD
                </li>
              </Spin>
            </ul>
            {discount !== 0 && (
              <ul className="summary_list">
                <li>Coupon Discount:</li>
                <Spin spinning={taxLoading}>
                  <li style={{ color: "red" }}>-{discount} USD</li>
                </Spin>
              </ul>
            )}
            {discount === 0 && purchaseDiscount !== 0 && (
              <ul className="summary_list">
                <li>Discount on purchase:</li>
                <li style={{ color: "red" }}>
                  -{parseFloat(purchaseDiscount).toFixed(2)} USD
                </li>
              </ul>
            )}

            <hr />
            <ul className="summary_list listb">
              <li style={{ color: "#A3341A", fontWeight: "bold" }}>
                ORDER TOTAL
              </li>
              <li style={{ color: "#A3341A" }}>
                {(
                  totalPrice +
                  shippingMethod?.shippingCherge +
                  shippingMethodPre?.shippingCherge +
                  (totalPrice - (discount ? 0 : purchaseDiscount)) * tax -
                  (discount ? 0 : purchaseDiscount)
                ).toFixed(2)}{" "}
                USD
              </li>
            </ul>
            <ul className="summary_list listb">
              <li className="d-flex align-items-center">
                <span style={{ color: "#3d3d3d", fontWeight: "400" }}>
                  or 4 payments of USD{" "}
                  {(
                    (totalPrice +
                      shippingMethod?.shippingCherge +
                      shippingMethodPre?.shippingCherge +
                      (totalPrice - (discount ? 0 : purchaseDiscount)) * tax -
                      (discount ? 0 : purchaseDiscount)) /
                    4
                  ).toFixed(2)}{" "}
                  with
                </span>
                <span className="ms-1">
                  <AfterPaylogo width="70" />
                </span>
                <span className="mx-2">or</span>
                <img src="/images/klarna.svg" alt="" width="50" />
                <Tooltip title="Pay later in 4 installments. The first payment is taken when the order is processed and the remaining 3 are automatically taken at scheduled intervals">
                  <span className="ms-1">
                    <InfoIco width="12" />
                  </span>
                </Tooltip>
              </li>
            </ul>
            <div
              className="mt-3"
              style={{
                background: "#F9F7F2",
                padding: "10px",
                border: "1px solid lightgray",
              }}
            >
              <p className="mb-2">How are shipping costs calculated?</p>
              <p>
                Shipping costs are based on our shipping policies, for more
                information please scroll down to our policy sections or visit
                our <Link to="/faq">FAQ</Link> page
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="shipping_policy">
        <div className="d-flex align-items-center mb-2">
          <h5
            style={{ fontSize: "14px", fontWeight: "700" }}
            className="text-uppercase"
          >
            Coupon code
          </h5>{" "}
          <Tooltip title="Coupon will be apply to only regular price products.">
            <i
              style={{ fontSize: "12px", color: "#3d3d3d" }}
              className="far fa-question-circle ms-2"
            ></i>
          </Tooltip>
        </div>
        <div className="m" style={{ margin: "13px 0" }}>
          <div className="d-flex justify-content-between align-items-center">
            <input
              placeholder="Code"
              className="form-control"
              value={couponCode}
              onChange={onCouponChange}
              style={{ width: "70%" }}
            />
            <button
              style={{ width: "25%", borderRadius: "0.25rem", margin: 0 }}
              className="btn btn-common"
              disabled={!couponCode || loading}
              onClick={discount ? removeCoupon : fetchCouponCode}
            >
              {loading ? (
                <i className="fas fa-spinner fa-spin mr-2"></i>
              ) : discount ? (
                "Remove"
              ) : (
                "Apply"
              )}
            </button>
          </div>
          <p className="mb-1" style={{ color: "rgb(163, 52, 26)" }}>
            {couponResponseMsg}
          </p>
        </div>

        <div className="coupon-info">
          <p className="mb-1">1. Coupon can not apply on discounted product.</p>
          <p className="mb-1">2. Only one coupon can be used per order.</p>
          <div style={{ color: "grey" }} className="d-flex">
            <p className="me-1">3. </p>
            <p>
              When orders are cancelled, the coupon you applied to the order
              will be placed back into your account.
            </p>
          </div>
        </div>
      </div>
      <div className="shipping_policy">
        <Spin spinning={policyLoading}>
          <div className="accordion" id="accordionExample">
            {allPolicy?.map((item) => (
              <SinglePolicy key={item._id} item={item} />
            ))}
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default OrderSummary;
