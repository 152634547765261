// //auth
// export { default as SignUpPage } from "./SignUp";
// export { default as SignIn } from "./SignIn";
// export { default as ForgotPassword } from "./ForgotPassword";
// export { default as ResetPassword } from "./ResetPassword";
// export { default as EmailVerificationReq } from "./EmailVerificationReq";
// export { default as EmailVerify } from "./EmailVerify";
// export { default as NewEmailVerify } from "./NewEmailVerify";
// export { default as Unsubscribe } from "./Unsubscribe";

// //componenets

// export { default as Home } from "./Home";
// export { default as Test } from "./Test";
// export { default as ProductList } from "./ProductsList";
// export { default as WelcomeBoard } from "./WelcomeBoard";
// export { default as Checkout } from "./Checkout";
// export { default as ProductDetails } from "./ProductDetails";
// export { default as OrderDetails } from "./OrderDetails";
// export { default as OrderConfirmation } from "./OrderConfirmation";
// export { default as ProccesingOrder } from "./ProccessingOrder";
// export { default as NotFound } from "./NotFound";
// export { default as Contact } from "./Contact";
// export { default as Customization } from "./Customization";
// export { default as Payment } from "./Payment";
// export { default as Return_and_exchange } from "./Return_and_exchange";
// export { default as About } from "./About";
// export { default as Join } from "./Join";
// export { default as Shipping } from "./Shipping";
// export { default as FAQ } from "./FAQ";
// export { default as Legal } from "./Legal";
// export { default as OutOfStock } from "./OutOfStock";

import { lazy } from "react";
// auth
export const SignUpPage = lazy(() => import("./SignUp"));
export const SignIn = lazy(() => import("./SignIn"));
export const ForgotPassword = lazy(() => import("./ForgotPassword"));
export const ResetPassword = lazy(() => import("./ResetPassword"));
export const EmailVerificationReq = lazy(() =>
  import("./EmailVerificationReq")
);
export const EmailVerify = lazy(() => import("./EmailVerify"));
export const NewEmailVerify = lazy(() => import("./NewEmailVerify"));
export const Unsubscribe = lazy(() => import("./Unsubscribe"));

// others component
export const Home = lazy(() => import("./Home"));
export const Test = lazy(() => import("./Test"));
export const ProductList = lazy(() => import("./ProductsList"));
export const WelcomeBoard = lazy(() => import("./WelcomeBoard"));
export const Checkout = lazy(() => import("./Checkout"));
export const ProductDetails = lazy(() => import("./ProductDetails"));
export const OrderDetails = lazy(() => import("./OrderDetails"));
export const OrderConfirmation = lazy(() => import("./OrderConfirmation"));
export const ProccesingOrder = lazy(() => import("./ProccessingOrder"));
export const NotFound = lazy(() => import("./NotFound"));
export const Contact = lazy(() => import("./Contact"));
export const Customization = lazy(() => import("./Customization"));
export const Payment = lazy(() => import("./Payment"));
export const Return_and_exchange = lazy(() => import("./Return_and_exchange"));
export const About = lazy(() => import("./About"));
export const Join = lazy(() => import("./Join"));
export const Shipping = lazy(() => import("./Shipping"));
export const FAQ = lazy(() => import("./FAQ"));
export const Legal = lazy(() => import("./Legal"));
export const OutOfStock = lazy(() => import("./OutOfStock"));
export const ReturnOrder = lazy(() => import("./ReturnOrder"));
