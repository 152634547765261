import gql from "graphql-tag";

export const CREATE_ORDER = gql`
  mutation CreateOrder($data: OrderInput!) {
    CreateOrder(data: $data) {
      code
      success
      message
      _id
    }
  }
`;

export const FETCH_ALL_ORDER_CLIENT = gql`
  query FetchAllOrder {
    FetchAllOrder {
      code
      message
      count
      result {
        _id
        # firstname
        # lastname
        email
        status
        createdAt
        shippingAddress {
          _id
          country
          state
          zipCode
          city
          address
          phone
        }
        items {
          _id
          gallery
          haveDiscount
          discount
          topSizing
          bottomSizing
          bottomColor
          bottomColorName
          topColor
          topColorName
        }
      }
    }
  }
`;

export const FETCH_SINGLE_ORDER = gql`
  query FetchSingleOrder($orderId: ID) {
    FetchSingleOrder(orderId: $orderId) {
      code
      success
      message
      result {
        createdAt
        status
        _id
        cardType
        orderId
        trackingUrl
        trackingNumber
        totalPrice
        tax
        grandTotal
        shippingCherge
        shippingDurationText
        preOrderTrackingNumber
        preOrderTrackingUrl
        preOrderShippingType
        orderNote
        couponDiscount
        discountOnTotalPrice
        coupon {
          discountInPercent
        }
        shippingType
        cardNo
        shippingAddress {
          _id
          country
          state
          apertment
          city
          zipCode
          address
          firstname
          lastname
          phone
        }
        billingAddress {
          _id
          country
          state
          city
          zipCode
          address
          apertment
          firstname
          lastname
          phone
        }
        items {
          _id
          name
          gallery
          price
          haveDiscount
          discount
          discountType
          quantity
          topSizing
          bottomSizing
          topColor
          topColorName
          bottomColor
          bottomColorName
          isShipped
          isPreOrderItem
          isTopPre
          isBottomPre
          isFreePoolItem
          style
          product {
            isAccessories
            styles {
              name
              url
            }
          }
        }
      }
    }
  }
`;

export const FIND_ORDER_BY_ORDER_ID = gql`
  mutation FndOrderByOrderId($orderId: ID, $email: String) {
    FndOrderByOrderId(orderId: $orderId, email: $email) {
      code
      success
      message
      result {
        createdAt
        status
        _id
        cardType
        orderId
        trackingUrl
        trackingNumber
        totalPrice
        tax
        taxRate
        grandTotal
        shippingCherge
        shippingDurationText
        preOrderTrackingNumber
        preOrderTrackingUrl
        preOrderShippingType
        orderNote
        couponDiscount
        discountOnTotalPrice
        coupon {
          discountInPercent
        }
        shippingType
        cardNo
        shippingAddress {
          _id
          country
          state
          apertment
          city
          zipCode
          address
          firstname
          lastname
          phone
        }
        billingAddress {
          _id
          country
          state
          city
          zipCode
          address
          apertment
          firstname
          lastname
          phone
        }
        items {
          _id
          name
          gallery
          price
          haveDiscount
          discount
          discountType
          quantity
          topSizing
          bottomSizing
          topColor
          topColorName
          bottomColor
          bottomColorName
          isShipped
          isPreOrderItem
          isTopPre
          isBottomPre
          isFreePoolItem
          style
          returnStatus
          product {
            isAccessories
            styles {
              name
              url
            }
          }
        }
      }
    }
  }
`;

export const CANCEL_ORDER_BY_USER = gql`
  mutation CancelOrder($orderId: ID) {
    CancelOrder(orderId: $orderId) {
      code
      message
      success
    }
  }
`;

export const UPDATE_ORDER_ITEM_STATUS = gql`
  mutation UpdateOrderItemStatus(
    $orderItemId: ID
    $returnStatus: String
    $itemFieldInput: OrderItemUpdateInput
  ) {
    UpdateOrderItemStatus(
      orderItemId: $orderItemId
      returnStatus: $returnStatus
      itemFieldInput: $itemFieldInput
    ) {
      code
      success
      message
    }
  }
`;

export const TAX_BY_ZIPCODE = gql`
  mutation GetTaxByZipCode($zipCode: String, $country: String, $state: String) {
    GetTaxByZipCode(zipCode: $zipCode, country: $country, state: $state) {
      code
      message
      success
      tax
    }
  }
`;

export const FETCH_COUPON_DETAILS_BY_CODE = gql`
  mutation FetchCouponByCode($couponCode: String, $email: String) {
    FetchCouponByCode(couponCode: $couponCode, email: $email) {
      code
      message
      success
      coupon {
        _id
        code
        discountInPercent
        status
        createdAt
        validUntil
      }
    }
  }
`;

export const GET_PAYPAL_TOKEN = gql`
  mutation InitializePaypalPayment($purchaseAmount: Float) {
    InitializePaypalPayment(purchaseAmount: $purchaseAmount) {
      code
      success
      message
      _id
    }
  }
`;

export const GET_AFTERPAY_TOKEN = gql`
  mutation InitializeAfterPayPayment(
    $purchaseAmount: Float
    $orderItems: [ShoppingInput]
    $isPreOrder: Boolean
  ) {
    InitializeAfterPayPayment(
      purchaseAmount: $purchaseAmount
      orderItems: $orderItems
      isPreOrder: $isPreOrder
    ) {
      code
      success
      message
      _id
    }
  }
`;
